<template>
    <div class="createPromotionSpecialOffer">
        <el-card shadow="never" style="margin-top: 8px">
            <div style="display: flex; justify-content: space-between; padding-right: 20px">
                <el-steps
                    :active="1"
                    finish-status="success"
                    simple
                    style="margin-left: 14px; width: 300px; color: #1ff680; font-size: 14px"
                >
                    <el-step title="活动设置"></el-step>
                    <el-step title="投放设置" icon=""></el-step>
                </el-steps>

                <el-form>
                    <el-button
                        size="medium"
                        type="primary"
                        @click="nextStep"
                        v-if="
                            hasPrivilege('menu.promotion.npromotion.edit') ||
                            hasPrivilege('menu.promotion.npromotion.create')
                        "
                        >下一步</el-button
                    >
                </el-form>
            </div>
        </el-card>
        <el-card shadow="never" style="margin-top: 8px">
            <el-form :model="form" inline size="medium" :rules="rules" ref="fm">
                <div style="display: flex">
                    <el-form-item label="活动名称" prop="name">
                        <el-input v-model="form.name"></el-input>
                    </el-form-item>
                </div>
            </el-form>
            <el-form :model="form" size="medium" class="sfm">
                <span class="font16 bold">活动规则</span>
                <hr class="efHr" style="background-color: #ccc" />
                <el-form-item label="选择商品">
                    <el-radio-group v-model="goodsType">
                        <el-radio-button :label="1">全部</el-radio-button>
                        <el-radio-button :label="2">指定商品不参加</el-radio-button>
                        <el-radio-button :label="3">指定商品参加</el-radio-button>
                    </el-radio-group>
                    <el-button
                        type="text"
                        style="margin: auto 20px"
                        v-if="goodsType === 2"
                        @click="onPickSkuNotInPromotion"
                        >+选择不参加活动的商品
                    </el-button>
                    <template v-if="goodsType === 3">
                        <el-button type="text" style="margin: auto 20px" @click="onPickSkuInPromotion"
                            >+选择参加活动的商品
                        </el-button>
                        <span class="clr-textGray">点击表格行内“复制”可添加重复商品</span>
                    </template>
                </el-form-item>
                <el-form-item label="价格参考店面" style="width: 800px">
                    <efn-group-dept-cascader
                        size="medium"
                        v-model="organization"
                        @changeFull="onChangeDept"
                        :sel-all-dept="false"
                    ></efn-group-dept-cascader>
                    <span class="clr-textGray" style="margin-left: 40px"
                        >切换机构组将清空数据，下表零售价为价格参考店面的商品零售价格</span
                    >
                </el-form-item>
                <el-form-item label="规则设置" size="mini" v-if="goodsType !== 3">
                    <div style="display: inline-block">
                        <div style="margin: 5px auto" v-for="(ele, idx) in form.favour" :key="idx">
                            <span>购买商品数量满</span>
                            <ef-price-input
                                style="margin: auto 10px; width: 60px"
                                v-model="ele.count"
                                :precision="0"
                                :max="999999"
                                :min="1"
                            ></ef-price-input>
                            件
                            <span>,该商品打</span>
                            <ef-price-input
                                style="margin: auto 10px; width: 60px"
                                v-model="ele.discount"
                                :precision="1"
                                :max="10"
                                :min="0.1"
                            ></ef-price-input>
                            折
                            <span class="left40">
                                <template v-if="idx === 0">
                                    <el-button type="text" @click="onAddFavour" class="font14">添加规则</el-button>
                                    <span style="margin-left: 16px" class="clr-textGray">
                                        如全场单品每个满3件9折，满5件8.5折等，可添加多条规则
                                    </span>
                                    <span style="color: red; margin-left: 20px" v-if="!check.favour.valid">
                                        * {{ check.favour.message }}
                                    </span>
                                </template>
                                <el-button type="text" @click="onDelFavour(idx)" v-if="idx > 0" class="clr-pink font14">
                                    删除
                                </el-button>
                            </span>
                        </div>
                    </div>
                </el-form-item>

                <div v-if="goodsType === 2 || goodsType === 3">
                    <div style="display: flex; justify-content: space-between; align-content: center">
                        <el-form-item>
                            <el-button
                                type="text"
                                style="float: right; margin-right: 40px"
                                class="clr-pink"
                                @click="onAllGoodsDel"
                                >移除全部
                            </el-button>
                        </el-form-item>
                    </div>
                    <span v-if="!check.goods.valid" style="color: red">* {{ check.goods.message }}</span>
                </div>
            </el-form>
            <efn-table v-if="goodsType === 2" :data="meta.goodsNotInPromotion">
                <el-table-column type="index" label="No."></el-table-column>
                <el-table-column label="不参加活动的商品" width="220">
                    <template slot-scope="scope">
                        <div v-if="scope.$index < meta.goodsNotInPromotion.length - 1">
                            {{ scope.row['sku'].name }}
                        </div>
                        <div v-else>
                            <efn-good-pop-search
                                :sku-type="0"
                                clearable
                                size="mini"
                                v-model="scope.row.skuName"
                                :deptCode="form.deptCode"
                                @select="handleSelectPopTable"
                            ></efn-good-pop-search>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column label="规格" width="140" prop="specs">
                    <template slot-scope="scope">
                        <div v-if="scope.$index < meta.goodsNotInPromotion.length - 1">
                            {{ scope.row['sku'].specs }}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column label="参考店面" width="140" prop="price">
                    <template slot-scope="scope">
                        <span>{{ scope.row.deptName }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="零售价" width="140" prop="price">
                    <template slot-scope="scope">
                        <div v-if="scope.$index < meta.goodsNotInPromotion.length - 1 && scope.row.retailPrice">
                            {{ scope.row.retailPrice | money }}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column label=" " min-width="500"></el-table-column>
                <el-table-column label="操作" fixed="right" width="100">
                    <template slot-scope="scope">
                        <span v-if="scope.$index < meta.goodsNotInPromotion.length - 1">
                            <el-button type="text" class="clr-pink" @click="onDelGoodsNotInPromotion(scope.$index)"
                                >移除</el-button
                            >
                        </span>
                    </template>
                </el-table-column>
            </efn-table>
            <efn-table v-if="goodsType === 3" :data="meta.goodsInPromotion">
                <el-table-column type="index" label="No."></el-table-column>
                <el-table-column label="活动商品" width="220">
                    <template slot-scope="scope">
                        <div v-if="scope.$index < meta.goodsInPromotion.length - 1">
                            {{ scope.row.sku.name }}
                        </div>
                        <div v-else>
                            <efn-good-pop-search
                                size="mini"
                                clearable
                                :sku-type="0"
                                v-model="scope.row.skuName"
                                :deptCode="form.deptCode"
                                @select="handleSelectPopTable"
                            ></efn-good-pop-search>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column label="规格" width="140" prop="specs">
                    <template slot-scope="scope">
                        <div v-if="scope.$index < meta.goodsInPromotion.length - 1">
                            {{ scope.row.sku.specs }}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column label="参考店面" prop="deptName"></el-table-column>
                <el-table-column label="参考零售价" width="140">
                    <template slot-scope="scope">
                        <div v-if="scope.$index < meta.goodsInPromotion.length - 1 && scope.row.retailPrice">
                            {{ scope.row.retailPrice | money }}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column label="买满数量" width="140">
                    <template slot-scope="scope">
                        <div v-if="scope.$index < meta.goodsInPromotion.length - 1">
                            <ef-price-input
                                v-model="scope.row.count"
                                :min="1"
                                :max="999999"
                                :precision="0"
                            ></ef-price-input>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column label="折扣(折)" width="140">
                    <template slot-scope="scope">
                        <div v-if="scope.$index < meta.goodsInPromotion.length - 1">
                            <ef-price-input
                                :disabled="!scope.row.retailPrice"
                                v-model="scope.row.discount"
                                :precision="1"
                                :max="10"
                                :min="0.1"
                                @change="(value) => onLinePriceChange(value, scope.$index, 1)"
                            ></ef-price-input>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column label="活动单价" width="140">
                    <template slot-scope="scope">
                        <div v-if="scope.$index < meta.goodsInPromotion.length - 1">
                            <ef-price-input
                                v-model="scope.row.price"
                                :precision="2"
                                :min="0"
                                :max="scope.row.retailPrice / 10000"
                                @change="(value) => onLinePriceChange(value, scope.$index, 2)"
                            ></ef-price-input>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column></el-table-column>
                <el-table-column label="操作" fixed="right" width="100">
                    <template slot-scope="scope">
                        <span v-if="scope.$index < meta.goodsInPromotion.length - 1">
                            <el-button type="text" @click="onLineCopy(scope.$index)">复制</el-button>
                            <el-button type="text" class="clr-pink" @click="onLineRemoveGoodsInPromotion(scope.$index)"
                                >移除</el-button
                            >
                        </span>
                    </template>
                </el-table-column>
            </efn-table>
        </el-card>
        <pick-up-goods ref="pickupGoods" @queryGoods="handleSelectSkuOk" />
    </div>
</template>

<script>
import MoneyUtil from 'js/MoneyUtils';
import PickUpGoods from 'components/PickUpGoods';
import EfnGoodPopSearch from 'components/newStyle/EfnGoodPopSearch';
import EfPriceInput from 'components/EfPriceInput';

import { chain } from 'mathjs';
import EfnGroupDeptCascader from 'components/newStyle/EfnGroupDeptCascader';
import EfnTable from 'components/newStyle/EfnTable';

export default {
    name: 'CreatePromotionSpecialOffer',
    props: {
        editPromotionCode: {
            type: String,
            default: '',
        },
    },
    components: { EfnTable, EfnGroupDeptCascader, EfPriceInput, EfnGoodPopSearch, PickUpGoods },
    data() {
        return {
            c: chain,
            tableSearch: '',
            goodsType: 1,
            form: {
                code: '',
                name: '',
                groupCode: '',
                deptCode: '',
                deptName: '',
                allGoodsFlag: true,
                allGoodsCountType: 1, //1，单独计数，2：全场合计
                rule: 4,
                goods: [],
                favour: [
                    {
                        count: null,
                        discount: null,
                    },
                ],
            },
            rules: {
                name: [
                    { required: true, message: '请输入活动名称', trigger: 'blur' },
                    { min: 5, max: 40, message: '长度在 5 到 40 个字', trigger: 'blur' },
                ],
            },
            meta: {
                goodsNotInPromotion: [{ code: '&nbsp', skuName: ' ' }],
                goodsInPromotion: [{ code: ' ', skuName: ' ' }],
            },
            url: {
                makeCode: '/npromotion/code',
                goodsBySkuAndDept: '/goods/basic/listBySkuCodes',
                save: '/npromotion/detail',
            },
            check: {
                favour: {
                    message: '',
                    valid: true,
                },
                goods: {
                    message: '',
                    valid: true,
                },
            },
        };
    },
    computed: {
        organization: {
            get() {
                return [this.form.groupCode, this.form.deptCode];
            },
            set(data) {
                if (this.form.groupCode !== data[0]) {
                    this.meta.goodsNotInPromotion = [{}];
                    this.meta.goodsInPromotion = [{}];
                }
                this.form.groupCode = data[0];
                this.form.deptCode = data[1];
            },
        },
    },
    watch: {
        goodsType(value) {
            this.form.allGoodsCountType = 1;
            this.form.allGoodsFlag = value !== 3;
        },
    },
    mounted() {
        const _this = this;
        if (this.editPromotionCode) {
            //编辑
            this.$http.get(this.url.save + '/' + this.editPromotionCode).then((rsp) => {
                _this.form = rsp.data.data;
                _this.form.code = _this.editPromotionCode;
                _this.goodsType = _this.form.allGoodsFlag ? (_this.form.goods.length ? 2 : 1) : 3;
                _this.refreshGoodsList();
                _this.refreshFavourList();
            });
        } else {
            //新建
            this.$http.get(this.url.makeCode).then((rsp) => (_this.form.code = rsp.data));
        }
    },
    methods: {
        async nextStep() {
            const _this = this;
            const rst = await _this.$refs.fm.validate().catch(() => {});
            if (!rst) {
                return;
            }
            _this.check.favour.valid = _this.checkFavour();
            if (!_this.check.favour.valid) {
                return false;
            }
            // 校验 goods 存在且 同sku count不重复
            _this.check.goods.valid = _this.checkGoods();
            if (!_this.check.goods.valid) {
                return false;
            }
            if (_this.goodsType == 1) {
                _this.form.goods = [];
            }
            if (_this.goodsType === 2) {
                //指定商品不参加
                const _source = _this.meta.goodsNotInPromotion.slice();
                _source.splice(_source.length - 1, 1);
                _this.form.goods = _source.map((s) => {
                    const g = {};
                    g.skuCode = s.sku.code;
                    g.deptName = s.deptName;
                    g.deptCode = s.deptCode;
                    g.oriPrice = s.retailPrice;
                    return g;
                });
            } else if (_this.goodsType === 3) {
                //指定商品参加
                const _source = _this.meta.goodsInPromotion.slice();
                _source.splice(_source.length - 1, 1);
                _this.form.favour = [];
                _this.form.goods = _source.map((s) => {
                    const g = {};
                    g.skuCode = s.sku.code;
                    g.deptName = s.deptName;
                    g.deptCode = s.deptCode;
                    g.count = s.count;
                    g.oriPrice = s.retailPrice;
                    g.discount = s.discount;
                    g.price = MoneyUtil.moneyToDb(s.price);
                    return g;
                });
            }
            const _tempFavour = _this.form.favour.slice();
            _this.$http
                .post(_this.url.save + '/' + _this.form.code, _this.form)
                .then(() => {
                    _this.$message.success('保存成功');
                    _this.$router.push({
                        name: 'menu.promotion.npromotion.create.launch',
                        params: { promotionCode: _this.form.code },
                    });
                    _this.$store.commit('delTabRoute', 'menu.promotion.npromotion.create.specialOffer');
                })
                .catch(() => {
                    if (_this.goodsType === 3) {
                        _this.form.favour = _tempFavour;
                    }
                });
        },
        onChangeDept(data) {
            this.form.deptName = data.deptName;
        },
        onAddFavour() {
            this.form.favour.splice(this.form.favour.length, 0, {
                count: null,
                discount: null,
            });
        },
        onDelFavour(idx) {
            this.form.favour.splice(idx, 1);
        },
        onAllGoodsDel() {
            this.meta.goodsNotInPromotion = [{ code: '' }];
            this.meta.goodsInPromotion = [{ code: '' }];
        },
        onDelGoodsNotInPromotion(idx) {
            this.meta.goodsNotInPromotion.splice(idx, 1);
        },
        onLineRemoveGoodsInPromotion(idx) {
            this.meta.goodsInPromotion.splice(idx, 1);
        },
        onLineCopy(idx) {
            const _a = {};
            Object.assign(_a, this.meta.goodsInPromotion[idx]);
            this.meta.goodsInPromotion.splice(idx, 0, _a);
        },
        onPickSkuNotInPromotion() {
            const _this = this;
            this.$refs.pickupGoods.show(
                {
                    deptCode: _this.form.deptCode,
                    skuType: 0,
                },
                _this.form.deptName
            );
        },
        onPickSkuInPromotion() {
            const _this = this;
            this.$refs.pickupGoods.show(
                {
                    deptCode: _this.form.deptCode,
                    skuType: 0,
                },
                _this.form.deptName
            );
        },
        handleSelectPopTable(item) {
            this.handleSelectSkuOk([item]);
        },
        async handleSelectSkuOk(data) {
            if (!data.length) {
                return;
            }
            if (this.goodsType === 3) {
                const _d = data.filter((d) => !d.sku.type);
                if (_d.length !== data.length) {
                    this.$message.info('非标品不能参与活动');
                    if (!_d.length) {
                        this.resetPopInput();
                        return;
                    }
                }
                data = _d;
            }

            let _array = this.currentArray();
            const existCodes = _array.filter((a) => a['sku']).map((a) => a['sku']['code']);
            const skuToAdd = data.filter((a) => existCodes.findIndex((code) => code === a.sku.code) < 0);
            if (!skuToAdd.length) {
                this.$message.info('该商品已存在');
                this.resetPopInput();
                return;
            }
            _array.splice(_array.length - 1, 1);
            _array = _array.concat(skuToAdd).concat({ code: '' });
            if (this.goodsType === 2) {
                this.meta.goodsNotInPromotion = _array;
            } else if (this.goodsType === 3) {
                this.meta.goodsInPromotion = _array;
            }
        },
        checkFavour() {
            //指定商品活动， 不校验favour
            if (this.goodsType === 3) {
                this.check.favour.message = '';
                return true;
            }
            if (!this.form.favour.length) {
                this.check.favour.message = '至少添加一个活动规则';
                return false;
            }
            if (this.form.favour.find((e) => e.count == null)) {
                this.check.favour.message = '必须填写商品件数';
                return false;
            }
            if (this.form.favour.find((e) => e.discount == null)) {
                this.check.favour.message = '必须填写活动折扣';
                return false;
            }
            const s = new Set();
            for (const i in this.form.favour) {
                if (i == this.form.favour.length - 1) {
                    break;
                }
                const g = this.form.favour[i];
                if (!g.count) {
                    this.check.favour.message = '购买商品数量必须是正整数';
                    return false;
                }
                if (!g.discount) {
                    this.check.favour.message = '必须填写活动折扣';
                    return false;
                }
                if (s.has(g.count)) {
                    this.check.favour.message = '商品购买数量不能重复';
                    return false;
                } else {
                    s.add(g.count);
                }
            }
            this.check.favour.message = '';
            return true;
        },
        checkGoods() {
            //指定商品活动，仅校验goods
            if (this.goodsType !== 3) {
                this.check.goods.message = '';
                return true;
            }

            if (this.meta.goodsInPromotion.length < 2) {
                this.check.goods.message = '至少添加一个活动商品';
                return false;
            }

            const skuDistinctObj = {};
            for (const i in this.meta.goodsInPromotion) {
                const g = this.meta.goodsInPromotion[i];
                if (i == this.meta.goodsInPromotion.length - 1) {
                    break;
                }
                if (!g.count) {
                    this.check.goods.message = '购买商品数量必须是正整数';
                    return false;
                }
                if (!g.price) {
                    this.check.goods.message = '必须填写活动单价';
                    return false;
                }
                const _skuCode = g.sku.code;
                if (typeof skuDistinctObj[_skuCode] == 'undefined') {
                    skuDistinctObj[_skuCode] = new Set();
                }
                const s = skuDistinctObj[_skuCode];
                if (s.has(g.count)) {
                    this.check.goods.message = '相同商品购买数量不能重复';
                    return false;
                } else {
                    s.add(g.count);
                }
            }
            this.check.goods.message = '';
            return true;
        },
        onLinePriceChange(value, idx, flag) {
            const _this = this;
            let ele;
            if (flag === 1) {
                ele = this.meta.goodsInPromotion[idx];
                ele.price = _this
                    .c(ele.retailPrice || 0)
                    .divide(100000)
                    .multiply(value)
                    .done();
            } else if (flag === 2) {
                //price->discount
                ele = this.meta.goodsInPromotion[idx];
                if (!ele.retailPrice) {
                    ele.discount = null;
                } else {
                    ele.discount = _this.c(ele.price).divide(ele.retailPrice).multiply(100000).done();
                }
            }
            this.$set(this.meta.goodsInPromotion, idx, ele);
        },
        refreshGoodsList() {
            const _this = this;
            const _array = _this.form.goods.map((r) => {
                return {
                    sku: {
                        code: r.skuCode,
                        specs: r.specs,
                        name: r.goodsName,
                    },
                    count: r.count,
                    retailPrice: r.oriPrice,
                    discount: r.discount,
                    price: MoneyUtil.moneyToYuan(r.price),
                    deptName: r.deptName,
                    deptCode: r.deptCode,
                };
            });
            _array.splice(_array.length, 0, {});
            if (_this.goodsType === 3) {
                _this.meta.goodsInPromotion = _array;
            } else {
                _this.meta.goodsNotInPromotion = _array;
            }
        },
        refreshFavourList() {
            if (!this.form['favour'] || !this.form.favour.length) {
                this.form['favour'] = [
                    {
                        count: null,
                        discount: null,
                    },
                ];
            }
        },
        resetPopInput() {
            const _array = this.currentArray();
            const obj = _array[_array.length - 1];
            obj.skuName = '';
            _array.splice(_array.length - 1, 1, obj);
        },
        currentArray() {
            const _this = this;
            if (_this.goodsType === 2) {
                return _this.meta.goodsNotInPromotion;
            } else if (this.goodsType === 3) {
                return _this.meta.goodsInPromotion;
            } else {
                return [];
            }
        },
    },
};
</script>

<style>
.createPromotionSpecialOffer {
    color: #606266;
}
</style>
